import { Rates } from '../client/invoice-ui-utils'
import { CommonUtils, DetailedEntry } from './common-utils'
import { ActualPaymentKey, DetailedInvoice, Invoice, InvoiceEntry } from './data-invoices'
import { Settings } from './data-misc'
import { i18n } from './i18n'

export type Currency = 'MAD' | 'EUR'
export type CurrencyWithUsd = Currency | 'USD'

export interface UiInvoiceEntry {
  product: string,
  amount: number | string,
  price: number | string,
  priceOverride?: string,
}

export interface UiInvoiceBook {
  quantity: number | string,
  price: number | string,
}

export interface InvoiceValues {
  currency: Currency,
  entries: (UiInvoiceEntry | DetailedEntry)[],
  shipping: number | string,
  marraCashCard: boolean,
  book: UiInvoiceBook,
}

export interface ExpenseAmounts {
  guideCommission?: number,
  cardCommission?: number,
  vat?: number,
}

interface AccountingArtifacts {
  earning: number,
  expenses?: ExpenseAmounts,
}

export type InvoiceSettings = Pick<Settings, (
  | 'guideCommission'
  | 'cardCommission'
  | 'vatPercentage'
  | 'marraCashCardDiscount'
  | 'droitsDeTimbre'
)>

type ActualPaymentType = 'in-cash' | 'by-card' | 'cheque' | 'transfer'

export interface ActualPaymentOption {
  currency: CurrencyWithUsd,
  type?: ActualPaymentType,
}

export const InvoiceUtils = {
  getActualPaymentOptions: function (currency: Currency | null) {
    // Pass in currency = null to get all possible options

    const options: ActualPaymentOption[] = []

    if (currency === 'MAD' || currency === null) {
      options.push({ currency: 'MAD', type: 'in-cash' })
    }

    options.push({ currency: 'MAD', type: 'by-card' })
    options.push({ currency: 'MAD', type: 'cheque' })
    options.push({ currency: 'MAD', type: 'transfer' })
    options.push({ currency: 'EUR' })
    options.push({ currency: 'USD' })
    return options
  },
  getCashOptions: function (currency: Currency) {
    return InvoiceUtils.getActualPaymentOptions(currency)
      .filter(function (option) {
        return !option.type || option.type === 'in-cash'
      })
  },
  getActualPaymentKey: function (option: ActualPaymentOption): ActualPaymentKey {
    return option.type ? `${option.currency}-${option.type}` : option.currency as any
  },
  getActualPaymentString: function (amount: number, option: ActualPaymentOption, forPrint: boolean): string {
    return (
      CommonUtils.formatDecimal(amount, true) + ' ' +
      InvoiceUtils.getActualPaymentOptionString(option, forPrint)
    )
  },
  getActualPaymentOptionString: function (option: ActualPaymentOption, forPrint: boolean) {
    let str = option.currency

    if (option.type) {
      const key = 'invoice.actual.' + option.type

      // When printing, always use English
      str += ' ' + (forPrint ? i18n.lt('en', key) : i18n.t(key))
    }

    return str
  },
  getSortedActualPayments: function (
    actualPayments: Partial<Record<ActualPaymentKey, string | number>>, // TODO string only
    exchangeRates: Rates,
    currency: Currency,
    forPrint: boolean,
  ) {
    return InvoiceUtils.getActualPaymentOptions(currency)
      .map(function (option) {
        const key = InvoiceUtils.getActualPaymentKey(option)
        const amount = Number(actualPayments[key]) || 0

        return {
          key,
          amountInMad: amount * exchangeRates[option.currency],
          str: InvoiceUtils.getActualPaymentString(amount, option, forPrint),
        }
      })
      .filter(function (payment) {
        return payment.amountInMad !== 0
      })
      .sort(function (payment1, payment2) {
        // Sort by value, largest first
        return payment2.amountInMad - payment1.amountInMad
      })
  },
  getActualPaymentMap: function <T>(value: T, currency: Currency) {
    const map: Partial<Record<ActualPaymentKey, T>> = {}

    InvoiceUtils.getActualPaymentOptions(currency)
      .forEach(function (option) {
        const key = InvoiceUtils.getActualPaymentKey(option)
        map[key] = CommonUtils.clone(value)
      })

    return map
  },
  deductVat: function (invoiceSettings: InvoiceSettings, price: number) {
    return price * 100 / (100 + invoiceSettings.vatPercentage)
  },
  hasOverride: function (entry: InvoiceEntry | UiInvoiceEntry | DetailedEntry): entry is UiInvoiceEntry {
    return 'priceOverride' in entry && entry.priceOverride !== null
  },
  getDefaultPrice: function (
    invoiceSettings: InvoiceSettings | null,
    entry: InvoiceEntry | UiInvoiceEntry | DetailedEntry,
    deductVat: boolean,
  ) {
    // invoiceSettings can be null if deductVat is false

    return deductVat ? InvoiceUtils.deductVat(invoiceSettings, Number(entry.price)) : entry.price
  },
  getEntryUnitPrice: function (
    invoiceSettings: InvoiceSettings | null,
    entry: InvoiceEntry | UiInvoiceEntry | DetailedEntry,
    deductVat: boolean,
  ) {
    // invoiceSettings can be null if deductVat is false

    if (InvoiceUtils.hasOverride(entry)) {
      return entry.priceOverride
    }
    else {
      return InvoiceUtils.getDefaultPrice(invoiceSettings, entry, deductVat)
    }
  },
  getEntryUnitPriceWithoutVat: function (entry: DetailedEntry) {
    return InvoiceUtils.getEntryUnitPrice(null, entry, false)
  },
  getEntryTotal: function (
    invoiceSettings: InvoiceSettings | null,
    entry: InvoiceEntry | UiInvoiceEntry | DetailedEntry,
    deductVat: boolean,
  ) {
    // invoiceSettings can be null if deductVat is false

    // TODO: remove detailed entries format?
    // And use db format everywhere so we can always use entry.amount
    const amount: number | string = 'entryAmount' in entry ? entry.entryAmount : Number(entry.amount)
    return Number(amount) * Number(InvoiceUtils.getEntryUnitPrice(invoiceSettings, entry, deductVat))
  },
  getEntryTotalWithoutVat: function (entry: DetailedEntry) {
    return InvoiceUtils.getEntryTotal(null, entry, false)
  },
  getTotal: function (
    invoiceSettings: InvoiceSettings,
    invoiceValues: Invoice | InvoiceValues,
    deductVat: boolean,
  ) {
    // Does not include droits de timbre

    function sum(a: number, b: number) {
      return a + b
    }

    let total = invoiceValues.entries.map(function (entry: InvoiceEntry | UiInvoiceEntry) {
      return InvoiceUtils.getEntryTotal(invoiceSettings, entry, deductVat)
    }).reduce(sum, 0)

    if (invoiceValues.book) {
      total += Number(invoiceValues.book.quantity) * Number(invoiceValues.book.price)
    }

    total += Number(invoiceValues.shipping) || 0

    if (invoiceValues.marraCashCard && invoiceSettings.marraCashCardDiscount) {
      total *= 1 - invoiceSettings.marraCashCardDiscount / 100
    }

    return total
  },
  calculateTotals: function (invoiceSettings: InvoiceSettings, total: number, isExport: boolean) {
    let subtotal = total

    if (!isExport) {
      subtotal = CommonUtils.round(total * 100 / (100 + invoiceSettings.vatPercentage))
    }

    return { subtotal, vat: total - subtotal, total }
  },
  getValues: function (invoice: Invoice | DetailedInvoice, currency: Currency): InvoiceValues {
    return {
      currency,
      entries: invoice.entries,
      shipping: invoice.shipping,
      marraCashCard: invoice.marraCashCard,
      book: invoice.book,
    }
  },
  getGuideCommission: function (invoiceSettings: InvoiceSettings, total: number) {
    return total * invoiceSettings.guideCommission / 100
  },
  getMarraCashCardDiscount: function (
    invoiceSettings: InvoiceSettings,
    invoiceValues: InvoiceValues,
    deductVat: boolean,
  ) {
    const modifiedValues = CommonUtils.clone(invoiceValues)
    modifiedValues.marraCashCard = false

    if (!invoiceSettings.marraCashCardDiscount) return 0;

    const total = InvoiceUtils.getTotal(invoiceSettings, modifiedValues, deductVat)
    return total * invoiceSettings.marraCashCardDiscount / -100
  },
  getDroitsDeTimbre: function (
    invoiceSettings: InvoiceSettings,
    exchangeRates: Rates,
    currency: Currency,
    actualPayments: Partial<Record<ActualPaymentKey, number | string>>, // TODO string only
  ) {
    if (currency !== 'MAD') {
      // Does not apply at EUR locations
      return 0
    }

    let totalForDroits = 0

    InvoiceUtils.getCashOptions(currency).forEach(function (option) {
      const key = InvoiceUtils.getActualPaymentKey(option)
      const amount = Number(actualPayments[key])

      if (!isNaN(amount)) {
        const amountInMad = amount * exchangeRates[option.currency]
        totalForDroits += amountInMad
      }
    })

    return totalForDroits * invoiceSettings.droitsDeTimbre / 100
  },
  getCardCommission: function (
    invoiceSettings: InvoiceSettings,
    actualPayments: Partial<Record<ActualPaymentKey, number | string>>, // TODO string only
  ): number {
    const cardAmount = Number(actualPayments['MAD-by-card'])
    return cardAmount * invoiceSettings.cardCommission / 100
  },

  getCoveredAmount: function (
    actualPayments: Partial<Record<ActualPaymentKey, number | string>>, // TODO string only
    locCurrency: Currency,
    rates: Rates,
  ): number {
    const locRate = rates[locCurrency]

    let covered = 0

    for (const key in actualPayments) {
      const amount = Number(actualPayments[key])

      if (!isNaN(amount)) {
        const paymentCurrency = key.substring(0, 3)
        const paymentRate = rates[paymentCurrency]

        covered += amount * paymentRate / locRate
      }
    }

    return covered
  },

  getAccountingArtifacts: function (
    settings: InvoiceSettings,
    invoice: Invoice,
    rates: Rates,
  ): AccountingArtifacts {
    const total = InvoiceUtils.getTotal(settings, invoice, invoice.export)
    const coveredAmt = InvoiceUtils.getCoveredAmount(invoice.actualPayments, 'MAD', rates)
    let earning = coveredAmt
    const expenses: ExpenseAmounts = {}
    let hasExpenses = false

    if (invoice.guideCommission) {
      hasExpenses = true
      const shipping = invoice.shipping || 0
      const totalWithoutShipping = total - shipping
      expenses.guideCommission = CommonUtils.round(
        InvoiceUtils.getGuideCommission(settings, totalWithoutShipping),
      )
      earning -= expenses.guideCommission
    }

    if (invoice.actualPayments['MAD-by-card']) {
      hasExpenses = true
      expenses.cardCommission = CommonUtils.round(
        InvoiceUtils.getCardCommission(settings, invoice.actualPayments),
      )
      earning -= CommonUtils.round(expenses.cardCommission)
    }

    if (!invoice.export) {
      hasExpenses = true
      const breakdown = InvoiceUtils.calculateTotals(settings, coveredAmt, invoice.export)
      expenses.vat = breakdown.vat
      earning -= expenses.vat
    }

    const artifacts: AccountingArtifacts = {
      earning: CommonUtils.round(earning),
    }

    if (hasExpenses) {
      artifacts.expenses = expenses
    }

    return artifacts
  },

  getSettings: async (adapterFunc): Promise<InvoiceSettings> => {
    // For use on both client and server side.
    // InvoiceUiUtils.getSettings provides the adapter for client side.
    const [
      guideCommission,
      cardCommission,
      vatPercentage,
      marraCashCardDiscount,
      droitsDeTimbre,
    ] = await adapterFunc(
      'guideCommission',
      'cardCommission',
      'vatPercentage',
      'marraCashCardDiscount',
      'droitsDeTimbre',
    ) as [
        Settings['guideCommission'],
        Settings['cardCommission'],
        Settings['vatPercentage'],
        Settings['marraCashCardDiscount'],
        Settings['droitsDeTimbre'],
      ]

    return {
      guideCommission,
      cardCommission,
      vatPercentage,
      marraCashCardDiscount,
      droitsDeTimbre,
    }
  },
}
