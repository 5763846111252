import { CurrencyWithUsd } from "./invoice-utils"

function toMap<T extends string>(arr: T[]): Record<T, T> {
  const result = {} as any

  arr.forEach(function (value) {
    result[value] = value
  })

  return result
}

export type Language = 'en' | 'fr'
const languages: Language[] = ['en', 'fr']

export type AttributeContext = 'materials' | 'products' | 'templates'
const attributeContexts: AttributeContext[] = ['materials', 'products', 'templates']

export type MaterialUnit = 'metres' | 'pieces'
const materialUnits: MaterialUnit[] = ['metres', 'pieces']

export type TransferStatus = 'draft' | 'sent' | 'accepted' | 'corrected'
const transferStatuses: TransferStatus[] = ['draft', 'sent', 'accepted', 'corrected']

/**
 * @apiDefine MaterialOrderStatus
 * @apiParamExample {Object} Material order statuses:
    {
      created: 'Initial status',
      confirmed: 'Order was confirmed',
      sent: 'Sent to the producer',
      received: 'Received from the producer'
    }
 */
export type MaterialOrderStatus = 'created' | 'confirmed' | 'sent' | 'received';
const materialOrderStatuses: MaterialOrderStatus[] = ['created', 'confirmed', 'sent', 'received'];

/**
 * @apiDefine PaymentType
 * @apiParamExample {Object} Payment types:
    {
      cash: 'Payment done with cash',
      card: 'Payment done with a debit/credit card',
      bakWire: 'Payment done via a bank wire'
    }
 */
export type PaymentType = 'cash' | 'card' | 'bankWire';
const paymentTypes: PaymentType[] = ['cash', 'card', 'bankWire'];

/**
 * @apiDefine AdditionalExpenseType
 * @apiParamExample {Object} Additional expense types:
    {
      shipping: 'Shipping cost from producer',
      analysis: 'Fabric quality analysis',
      storage: 'Storage',
      customs: 'Customs tax',
      delivery: 'Local delivery/transportation',
      other: 'Other/extra expenses'
    }
 */
export type AdditionalExpenseType = 'shipping' | 'analysis' | 'storage' | 'customs' | 'delivery' | 'other';
const additionalExpenseTypes: AdditionalExpenseType[] = ['shipping', 'analysis', 'storage', 'customs', 'delivery', 'other'];

export type MaterialAdditionType = 'A' | 'M';
const materialAdditionTypes: MaterialAdditionType[] = ['A', 'M'];

export type TailorOrderStatus = 'draft' | 'notStarted' | 'rejected' | 'cutting' | 'assembly' | 'finishing' | 'inspection' | 'ready' | 'completed';
const tailorOrderStatuses: TailorOrderStatus[] = ['draft', 'notStarted', 'rejected', 'cutting', 'assembly', 'finishing', 'inspection', 'ready', 'completed'];

export type LocationType = 'material' | 'production' | 'storage' | 'shop';
const locationTypes: LocationType[] = ['material', 'production', 'storage', 'shop'];

export type Role = (
  | 'admin'
  | 'powerUser'
  | 'assistant'
  | 'rawMaterials'
  | 'secretary'
  | 'production'
  | 'sales'
  | 'shopManager'
  | 'accountant'
  | 'tailor'
)

const roles: Role[] = [
  'admin',
  'powerUser',
  'assistant',
  'rawMaterials',
  'secretary',
  'production',
  'sales',
  'shopManager',
  'accountant',
  'tailor'
]

const locationBasedRoles: Role[] = ['assistant', 'sales', 'shopManager']

export type InvoiceExpenseCategory = 'company vat' | 'company guide-commission' | 'company card-commission'

const invoiceExpenseCategories: InvoiceExpenseCategory[] = [
  'company vat',
  'company guide-commission',
  'company card-commission',
]

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowUp: 38,
  arrowDown: 40,
}

const currencyTypes: CurrencyWithUsd[] = ['MAD', 'EUR', 'USD'];

export const Enums = {
  orderedLanguages: languages,
  languages: toMap(languages),

  orderedAttributeContexts: attributeContexts,
  attributeContexts: toMap(attributeContexts),

  orderedMaterialUnits: materialUnits,
  materialUnits: toMap(materialUnits),

  orderedTransferStatuses: transferStatuses,
  transferStatuses: toMap(transferStatuses),

  orderedRoles: roles,
  roles: toMap(roles),
  locationBasedRoles,

  orderedInvoiceExpenseCategories: invoiceExpenseCategories,
  invoiceExpenseCategories: toMap(invoiceExpenseCategories),

  keyCodes,

  orderedMaterialOrderStatuses: materialOrderStatuses,
  materialOrderStatuses: toMap(materialOrderStatuses),

  orderedPaymentTypes: paymentTypes,
  paymentTypes: toMap(paymentTypes),

  orderedAdditionalExpenseTypes: additionalExpenseTypes,
  additionalExpenseTypes: toMap(additionalExpenseTypes),

  orderedCurrencies: currencyTypes,
  currencies: toMap(currencyTypes),

  orderedMaterialAdditionTypes: materialAdditionTypes,
  materialAdditionTypes: toMap(materialAdditionTypes),

  orderedTailorOrderStatuses: tailorOrderStatuses,
  tailorOrderStatuses: toMap(tailorOrderStatuses),

  orderedLocationTypes: locationTypes,
  locationTypes: toMap(locationTypes),

  // Format for validator
  format: function <T extends string>(enumObj: Record<T, T>) {
    return function (value, validator, fieldName) {
      if (!value) {
        validator.addError(fieldName, 'required')
      }
      else if (!(value in enumObj)) {
        validator.addError(fieldName, 'invalid')
      }
    }
  },
}
