import { HTMLAttributes } from 'react'
import React = require('react')

import { strnatcmp } from '../client-lib'
import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { MaterialCategory, MaterialProducer, MaterialWidth, SimpleAttr } from '../common/data-misc'
import { MaterialAddition, MaterialAmount, RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { AttributeCombination } from '../common/types'
import { Comp } from './comp'
import { EditMaterialModal } from './edit-material-modal'
import { Filter, FilterManager } from './filter'
import { MainMenu } from './main-menu'
import { RawMaterialModals } from './raw-material-modals'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'
import { ViewMaterialInOutModal } from './view-material-int-out-modal'

interface Row {
  id: string,
  name: string,
  photo: string,
  discontinued: boolean,
  attributes: Record<string, string>,
  width: string,
  category: string,
  producer: string,
  amount: MaterialAmount,
  amountThreshold: number,
  price: number,
  additions: MaterialAddition[],
  note: string,
  material: RawMaterial,
}

interface AdditionsPopupProps {
  additions: MaterialAddition[],
  close: () => void,
}

interface AdditionsIconProps {
  additions: MaterialAddition[],
}

class AdditionsPopup extends React.Component<AdditionsPopupProps> {
  render() {
    return (
      <div
        ref="popup"
        className="additions-popup"
        style={{
          position: 'absolute',
          width: '16em',
          top: '1.6em',
          right: 0,
          backgroundColor: 'white',
          fontWeight: 'normal',
          boxShadow: '0px 0.15em 1em #808080',
          zIndex: 10,
        }}
      >
        <table
          className="table table-bordered table-condensed table-striped"
          style={{ margin: 0 }}
        >
          <tbody>
            <tr>
              <td colSpan={4}>
                {i18n.t('raw-materials.history-of-added')}
              </td>
            </tr>
            <tr>
              <td>{i18n.t('common.date')}</td>
              <td>{i18n.t('common.amount')}</td>
              <td>{i18n.t('common.price')}</td>
              <td>{i18n.t('common.type')}</td>
            </tr>
            {this.props.additions.map(function (addition, index) {
              const time = CommonUtils.toUserTime(User.getUser().country, new Date(addition.time))
              return (
                <tr key={index} className="row-addition">
                  <td>{CommonUtils.utcDateYMD(time)}</td>
                  <td>{CommonUtils.formatDecimal(addition.amount, true)}</td>
                  <td>{addition?.price} MAD</td>
                  <td>{addition?.type}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

// Function for closing previous opened additions panel.
// Only one can be open at a time.
let closePreviousPopup = null

class AdditionsIcon extends React.Component<AdditionsIconProps> {
  state = {
    popupVisible: false,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false

    if (closePreviousPopup === this.close) {
      closePreviousPopup = null
    }
  }

  close = () => {
    closePreviousPopup = null

    if (this._isMounted) {
      this.setState({ popupVisible: false })
    }
  }

  render() {
    let popup = null

    if (this.state.popupVisible) {
      popup = <AdditionsPopup additions={this.props.additions} close={this.close} />
    }

    return (
      <div style={{ position: 'relative', display: 'inline-block', marginLeft: '0.3em' }}>
        <img
          className="additions-icon"
          src="/img/expanded.png"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const willBeVisible = !this.state.popupVisible

            if (willBeVisible) {
              if (closePreviousPopup) {
                closePreviousPopup()
              }

              closePreviousPopup = this.close
            }
            else {
              closePreviousPopup = null
            }

            this.setState({ popupVisible: willBeVisible })
          }}
        />
        {popup}
      </div>
    )
  }
}

class List extends React.Component {
  state = {
    loaded: false,
    printView: false,
    includeDiscontinued: false,
    categories: undefined as MaterialCategory[] | undefined,
    producers: undefined as MaterialProducer[] | undefined,
    attributeDefinitions: undefined as AttributeDefinition[] | undefined,
    materials: undefined as RawMaterial[] | undefined,
    widths: undefined as MaterialWidth[] | undefined,
    locations: undefined as Location[] | undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()
    Filter.registerEvent(this)

    Promise.all([
      DataService.RawMaterials.getAll(),
      DataService.MaterialCategories.getAll(),
      DataService.MaterialProducers.getAll(),
      DataService.MaterialWidths.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.Locations.getByType('material')
    ])
      .then(([materials, categories, producers, widths, attributeDefinitions, locations]) => {
        if (!this._isMounted) {
          return
        }

        const filteredAttributeDefinitions = attributeDefinitions.filter(
          CommonUtils.attributeContextFilters.materials,
        )

        this.setState(
          {
            loaded: true,
            materials,
            categories,
            producers,
            widths,
            locations,
            attributeDefinitions: filteredAttributeDefinitions,
          },
          EventBus.fireFunc('materials-rendered'),
        )
      })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  reloadMaterials = () => {
    const scrollPosition = window.scrollY
    this.setState({ loaded: false })

    getDataService().RawMaterials.getAll()
      .then((materials) => {
        if (this._isMounted) {
          this.setState(
            { loaded: true, materials },
            function () {
              window.scrollTo(0, scrollPosition)
              EventBus.fire('materials-rendered')
            },
          )
        }
      })

    return null
  }

  hasPermissions = () => {
    return Access.editRawMaterialAmountThreshold(User.getUser())
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  getFilterConf = () => {
    const language = User.getLanguage()
    const categories = this.state.categories.slice()

    categories.sort(function (cat1, cat2) {
      return cat1.labels[language].localeCompare(cat2.labels[language])
    })

    const producers = this.state.producers.slice()

    producers.sort(function (prod1, prod2) {
      return prod1.labels[language].localeCompare(prod2.labels[language])
    })

    const filterConf = {
      category: {
        type: 'predefined',
        labelKey: 'common.category',
        options: categories.map(function (category) {
          return {
            value: category._id,
            label: category.labels[language],
          }
        }),
      },
      producer: {
        type: 'predefined',
        labelKey: 'raw-materials.producer',
        options: producers.map(function (producer) {
          return {
            value: producer._id,
            label: producer.labels[language],
          }
        }),
      },
    }

    this.state.attributeDefinitions.forEach((attribute) => {
      const comboSources = []

      this.state.materials.forEach(function (material) {
        if (attribute._id in material.attributes || !attribute.optional) {
          const comboSource = { values: material.attributes[attribute._id], category: null }
          comboSources.push(comboSource)
        }
      })

      filterConf[attribute._id] = {
        type: 'predefined-combo',
        label: attribute.pluralNames[language],
        options: Utils.getAttributeFilterOptions(attribute, language, comboSources),
        getField: function (material) {
          if (attribute.optional && !material.attributes[attribute._id]) {
            return []
          }

          return material.attributes[attribute._id]
        },
      }
    })

    return filterConf
  }

  getFilteredMaterials = (filterManager: FilterManager<RawMaterial>) => {
    function getSortKey(mat, attrDef) {
      const values = mat.attributes[attrDef._id]

      if (!values && attrDef.optional) {
        return ''
      }
      else {
        const language = User.getLanguage()
        return CommonUtils.getAttributeComboSortKey(attrDef, values, null, language)
      }
    }

    const { attributeDefinitions } = this.state
    let filteredMaterials = filterManager.getFiltered()

    if (!this.state.includeDiscontinued) {
      filteredMaterials = filteredMaterials.filter(function (material) {
        return !material.discontinued
      })
    }

    filteredMaterials.sort(function (mat1, mat2) {
      let result = mat1.name.localeCompare(mat2.name)

      // If name is the same, compare by attribute values

      for (let i = 0; result === 0 && i < attributeDefinitions.length; i += 1) {
        const attrDef = attributeDefinitions[i]
        const sortKey1 = getSortKey(mat1, attrDef)
        const sortKey2 = getSortKey(mat2, attrDef)
        result = strnatcmp(sortKey1, sortKey2)
      }

      return result
    })

    return filteredMaterials
  }

  getColumnConf = () => {
    const language = User.getLanguage()
    const { printView } = this.state

    const getNoWrap = function (): HTMLAttributes<HTMLTableCellElement> {
      return { style: { whiteSpace: 'nowrap' } }
    }

    const columns: Column<Row>[] = [
      {
        id: 'photo',
        header: i18n.t('raw-materials.photo'),
        getCellContents: this.renderPhoto,
        getTotalRowContents: function () {
          return i18n.t('common.total')
        },
      },
      {
        id: 'name',
        header: i18n.t('raw-materials.name'),
        getCellContents: function (rowData) {
          if (rowData.discontinued) {
            return (
              <span>
                <span style={{ textDecoration: 'line-through' }}>
                  {rowData.name}
                </span>
                {' ('}
                {i18n.t('common.discontinued').toLowerCase()}
                )
              </span>
            )
          }
          else {
            return rowData.name
          }
        },
      },
    ]

    this.state.attributeDefinitions.forEach(function (attr) {
      columns.push({
        id: 'attr-' + attr._id,
        header: attr.pluralNames[language],
        filterFieldName: attr._id,
        getCellContents: function (rowData) {
          return rowData.attributes[attr._id]
        },
      })
    })

    columns.push({
      id: 'width',
      header: i18n.t('raw-materials.width'),
      getCellProperties: getNoWrap,
    })

    columns.push({
      id: 'category',
      header: i18n.t('common.category'),
      filterFieldName: 'category',
    })

    columns.push({
      id: 'producer',
      header: i18n.t('raw-materials.producer'),
      filterFieldName: 'producer',
    })

    columns.push({
      id: 'amount',
      header: i18n.t('common.amount'),
      getCellProperties: getNoWrap,
      getCellContents: function (rowData) {
        let className = ''
        if (rowData.amountThreshold) {
          if (rowData.amount.value < rowData.amountThreshold) {
            className = 'text-red-bold'
          }
        }
        return (
          <span className={className}>
            {CommonUtils.formatDecimal(rowData.amount.value, true) + ' ' +
              i18n.t('enum.material-units.' + rowData.amount.unit)}
          </span>
        )
      },
    })

    columns.push({
      id: 'price',
      header: i18n.t('common.price'),
      getCellProperties: getNoWrap,
      getCellContents: function (rowData) {
        return CommonUtils.formatDecimal(rowData.price, true) + ' MAD'
      },
      getTotalRowContents: function (context) {
        return CommonUtils.formatDecimal(context.total) + ' MAD'
      },
    })

    columns.push({
      id: 'added',
      header: i18n.t('raw-materials.added'),
      getCellProperties: getNoWrap,
      getCellContents: this.renderAdditions,
    })

    columns.push({
      id: 'note',
      header: i18n.t('common.note'),
      getCellProperties: function () {
        return { className: 'note-column' }
      },
    })

    if (this.hasPermissions() && !printView) {
      columns.push({
        id: 'action',
        header: i18n.t('action.action'),
        getCellProperties: getNoWrap,
        getCellContents: this.renderActions,
      })
    }

    return columns
  }

  getRowsData = (filterManager: FilterManager<RawMaterial>, context) => {

    const language = User.getLanguage()
    const filteredMaterials = this.getFilteredMaterials(filterManager)
    context.total = 0

    return filteredMaterials.map((material): Row => {
      const category = CommonUtils.findById<MaterialCategory>(this.state.categories, material.category)
      const producer = CommonUtils.findById<MaterialProducer>(this.state.producers, material.producer)
      const width = CommonUtils.findById<MaterialWidth>(this.state.widths, material.width)

      const attributes: Record<string, string> = {}

      this.state.attributeDefinitions.map(function (attribute) {
        if (attribute._id in material.attributes) {
          const combination: AttributeCombination = {
            id: 0, // Should be ignored
            values: material.attributes[attribute._id],
          }

          const details = CommonUtils.getCombinationDetails(combination, attribute, null, language)
          attributes[attribute._id] = details.label
        }
      })

      context.total += material.amount.value * material.price

      return {
        id: material._id,
        name: material.name,
        photo: material.photo,
        discontinued: material.discontinued,
        attributes,
        width: width.labels[language],
        category: category.labels[language],
        producer: producer.labels[language],
        amount: material.amount,
        amountThreshold: material.amountThreshold,
        price: material.maxPrice,
        additions: material.additions,
        note: material.note,
        material, // Only for modals
      }
    })
  }

  renderMenu = () => {
    if (!this.state.printView) {
      return <MainMenu activeTab="raw-materials" />
    }
  }

  renderPrintButton = () => {
    return (
      <button onClick={this.print} style={{ float: 'right' }}>
        {i18n.t('common.print')}
      </button>
    )
  }

  renderAddNewButton = () => {
    if (!Access.manageRawMaterials(User.getUser())) {
      return null
    }

    return (
      <div>
        <button
          id="btn-add-new"
          onClick={function () {
            EventBus.fire('open-modal', { modalId: 'add-modal' })
          }}
        >
          {i18n.t('raw-materials.add-new')}
        </button>
        <EditMaterialModal
          modalId="add-modal"
          isNew={true}
          categories={this.state.categories}
          producers={this.state.producers}
          widths={this.state.widths}
          locations={this.state.locations.map(l => { return { _id: l._id, labels: l.names } as SimpleAttr })}
          attributeDefinitions={this.state.attributeDefinitions}
          afterSave={this.reloadMaterials}
        />
      </div>
    )
  }

  renderButtons = () => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    return (
      <div style={{ marginBottom: '0.5em' }}>
        {this.renderPrintButton()}
        {this.renderAddNewButton()}
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.raw-materials')}
        </h3>
      )
    }
  }

  renderAdditions = (rowData) => {
    const { additions } = rowData

    // Additions are always sorted newest to oldest
    const [addition] = additions

    if (addition) {
      const additionTime = CommonUtils.toUserTime(User.getUser().country, new Date(addition.time))

      let additionsIcon = null

      if (additions.length > 1 && !this.state.printView) {
        additionsIcon = <AdditionsIcon additions={additions} />
      }

      return (
        <span>
          {CommonUtils.utcDateYMD(additionTime)}
          {' '}
          {CommonUtils.formatDecimal(addition.amount, true)}
          {additionsIcon}
        </span>
      )
    }
  }

  renderProductionManagerActions = (rowData) => {
    const editModalId = 'edit-modal-' + rowData.id

    const editButton = (
      <img
        className="table-btn btn-edit"
        src="img/edit.png"
        title={i18n.t('action.edit')}
        onClick={function () {
          EventBus.fire('open-modal', { modalId: editModalId })
        }}
      />
    )

    const editModal = (
      <EditMaterialModal
        modalId={editModalId}
        material={rowData.material}
        categories={this.state.categories}
        producers={this.state.producers}
        widths={this.state.widths}
        locations={this.state.locations.map(l => { return { _id: l._id, labels: l.names } as SimpleAttr })}
        attributeDefinitions={this.state.attributeDefinitions}
        afterSave={this.reloadMaterials}
      />
    )

    return (
      <div>
        {editButton}
        {editModal}
      </div>
    )
  }

  renderActions = (rowData) => {
    if (!this.hasPermissions() || this.state.printView) {
      return null
    }

    const user = User.getUser()
    if (user.role === Enums.roles.production) {
      return this.renderProductionManagerActions(rowData)
    }

    const addAmountModalId = 'add-amount-modal-' + rowData.id

    const addAmountButton = (
      <img
        className="table-btn btn-add-amount"
        title={i18n.t('action.add')}
        src="img/add.png"
        onClick={function () {
          EventBus.fire('open-modal', { modalId: addAmountModalId })
        }}
      />
    )

    const addAmountModal = (
      <RawMaterialModals.Add
        modalId={addAmountModalId}
        material={rowData.material}
        afterSave={this.reloadMaterials}
      />
    )

    const cutAmountModalId = 'cut-amount-modal-' + rowData.id

    const cutAmountButton = (
      <img
        className="table-btn btn-cut"
        src="img/cut.png"
        title={i18n.t('raw-materials.cut')}
        onClick={function () {
          EventBus.fire('open-modal', { modalId: cutAmountModalId })
        }}
      />
    )

    const cutAmountModal = (
      <RawMaterialModals.Cut
        modalId={cutAmountModalId}
        material={rowData.material}
        afterSave={this.reloadMaterials}
      />
    )

    const editModalId = 'edit-modal-' + rowData.id

    const editButton = (
      <img
        className="table-btn btn-edit"
        src="img/edit.png"
        title={i18n.t('action.edit')}
        onClick={function () {
          EventBus.fire('open-modal', { modalId: editModalId })
        }}
      />
    )

    const editModal = (
      <EditMaterialModal
        modalId={editModalId}
        material={rowData.material}
        categories={this.state.categories}
        producers={this.state.producers}
        widths={this.state.widths}
        locations={this.state.locations.map(l => { return { _id: l._id, labels: l.names } as SimpleAttr })}
        attributeDefinitions={this.state.attributeDefinitions}
        afterSave={this.reloadMaterials}
      />
    )

    const inOutModelId = 'consumption-model-' + rowData.id;

    const InOutButton = (
      <img
        className="table-btn btn-in-out"
        src="img/change.png"
        title={i18n.t('action.material-consumption')}
        onClick={function () {
          EventBus.fire('open-modal', { modalId: inOutModelId })
        }}
      />
    )

    const inOutModal = (
      <ViewMaterialInOutModal
        modalId={inOutModelId}
        material={rowData.material}
      />
    )

    const deleteButton = (
      <img
        className="table-btn btn-delete"
        title={i18n.t('action.delete')}
        src="img/delete.png"
        onClick={() => {
          Utils.confirmTr('confirm.delete.material').then((confirmed) => {
            if (confirmed) {
              return getDataService().RawMaterials.delete(rowData.id).then(this.reloadMaterials)
            }
          })
        }}
      />
    )

    return (
      <div>
        {/* {addAmountButton} */}
        {cutAmountButton}
        {editButton}
        {InOutButton}
        {deleteButton}
        {/* {addAmountModal} */}
        {cutAmountModal}
        {editModal}
        {inOutModal}
      </div>
    )
  }

  renderPhoto = (rowData) => {
    if (rowData.photo) {
      return (
        <img
          src={User.getUploaded('fabrics/thumbs', rowData.photo)}
          style={{ cursor: 'pointer' }}
          title={i18n.t('image-upload.view-larger')}
          onClick={function () {
            UiUtils.openFancybox(User.getUploaded('fabrics', rowData.photo), rowData.name)
          }}
        />
      )
    }
  }

  renderDiscontinuedFilter = () => {
    return (
      <Comp.Checkbox
        checked={this.state.includeDiscontinued}
        label={i18n.t('raw-materials.include-discontinued')}
        onChange={(evt) => {
          this.setState({ includeDiscontinued: evt.target.checked })
        }}
      />
    )
  }

  renderTable = (filterManager: FilterManager<RawMaterial>) => {
    const context = {}
    const rowsData = this.getRowsData(filterManager, context)

    return UiUtils.getTable(
      this.getColumnConf(),
      rowsData,
      {
        tableId: 'tbl-mat',
        tableClassName: 'table table-bordered table-condensed table-striped',
        rowClassName: 'row-mat',
        filterManager,
        context,
        noItemsText: i18n.t('raw-materials.no-items'),
        noFilteredItemsText: i18n.t('raw-materials.no-filtered-items'),
        printView: this.state.printView,
      },
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenu()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const filterManager = Filter.createManager(
      'raw-materials', this.getFilterConf(), this.state.materials,
    )

    return (
      <div>
        {this.renderMenu()}
        {this.renderButtons()}
        {this.renderPrintHeader()}
        {this.renderDiscontinuedFilter()}
        {filterManager.getSummary()}
        {this.renderTable(filterManager)}
      </div>
    )
  }
}

export const RawMaterials = { List }
